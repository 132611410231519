<template>
  <!-- A ghost div to the error page actuate as main content -->
  <div></div>
  <div class="error-page">
    <img src="@/assets/sad.svg" alt="">
    <!-- Error 1 -->
    <h1 v-if="errorCode === codesCatalog.HASHTAH_NOT_FOUND">{{ $t('errors.hashtag_not_found') }}</h1>
    <!-- Error 2 -->
    <h1 v-else-if="errorCode === codesCatalog.SERVER_ERROR">{{ $t('errors.server_error') }}</h1>
    <!-- Error 3 -->
    <h1 v-else-if="errorCode === codesCatalog.SLOTS_NOT_FOUND">{{ $t('errors.slots_not_found') }}</h1>
    <!-- Error 424 and 494 -->
    <template v-else-if="[codesCatalog.FAILED_DEPENDENCY, codesCatalog.CONTACT_BLOCKED].includes(errorCode)">
      <h1> {{ $t('errors.scheduling.failed_dependency_title') }} </h1>
      <h1 style="margin-top: 20px;">
        {{ officePhone ?
          $t('errors.scheduling.failed_dependency_body', { officePhone : `(${officePhone})` }) :
          $t('errors.scheduling.failed_dependency_body')
        }}
      </h1>
    </template>
    <!-- Error 504 Gateway Timeout -->
    <template v-else-if="errorCode === codesCatalog.GATEWAY_TIMEOUT">
      <h1> {{ $t('errors.scheduling.gateway_timeout_title') }} </h1>
      <h1 style="margin-top: 20px;">
        {{ $t('errors.scheduling.gateway_timeout_body', { officePhone }) }}
      </h1>
    </template>
  </div>
  <Footer/>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Footer from '@/components/Footer.vue'
import { ErrorCodes } from '@/types/ErrorCodes'
import { store } from '@/store'

@Options({
  components: {
    Footer
  }
})
export default class Error extends Vue {
  errorCode = 0
  codesCatalog = ErrorCodes

  get officePhone () : string {
    return store.state.officePhone
  }

  mounted () : void {
    const query = this.$route.query as any
    this.errorCode = +query.code
  }
}
</script>
