
import { Options, Vue } from 'vue-class-component'
import Footer from '@/components/Footer.vue'
import { ErrorCodes } from '@/types/ErrorCodes'
import { store } from '@/store'

@Options({
  components: {
    Footer
  }
})
export default class Error extends Vue {
  errorCode = 0
  codesCatalog = ErrorCodes

  get officePhone () : string {
    return store.state.officePhone
  }

  mounted () : void {
    const query = this.$route.query as any
    this.errorCode = +query.code
  }
}
